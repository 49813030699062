import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import Papa from 'papaparse';
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

function ExcelImport() { 

  const { id } = useParams();
  
  const [csvData, setCsvData] = useState([]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate(); 
  let table = null;
  useEffect(() => {
    const fetchData = async () => {
      try { 
        await getTests(); 
      } catch (error) {
        console.error("Error loading data:", error);
        setIsLoading(false); // Stop loading even if there's an error
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    if(data.length > 0){
      if(table) {
        table.destroy();
      }
      table = new DataTable('#satlist',{
        'lengthMenu':[[10,25,50,100,-1],[10,25,50,100,"All"]],
        'pageLength': 100,
        "initComplete": function () { 
          setIsLoading(false); // Hide loader once the table and filters are initialized
        }
      });
    }
  }, [data]);  
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [filteredSubtopics, setFilteredSubtopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (!selectedQuestion || !selectedQuestion.topic) return; // Ensure selectedQuestion is defined
  
    const getTopicsAndSubtopics = async () => {
      try {
        const [topicsResponse, subtopicsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}getalltopics`),
          axios.get(`${process.env.REACT_APP_BASE_URL}getallsubtopics?topic_id=${selectedQuestion.topic}`)
        ]);
  
        if (topicsResponse.status === 200) setTopics(topicsResponse.data);
        if (subtopicsResponse.status === 200) setSubtopics(subtopicsResponse.data);
      } catch (error) {
        console.error("Error fetching topics/subtopics:", error);
      }
    };
  
    getTopicsAndSubtopics();
  }, [selectedQuestion?.topic]); // Only runs when selectedQuestion.topic changes
  
  useEffect(() => {
    if (selectedTopic) {
      const filtered = subtopics.filter(subtopic => subtopic.topic_id === selectedTopic);
      setFilteredSubtopics(filtered);
    } else {
      setFilteredSubtopics([]);
    }
  }, [selectedTopic, subtopics]); // Separate filtering logic
  
  
  useEffect(() => {
    if (selectedTopic) {
      const filtered = subtopics.filter(subtopic => subtopic.topic_id === selectedTopic);
      setFilteredSubtopics(filtered);
    } else {
      setFilteredSubtopics([]);
    }
  }, [selectedTopic, subtopics]); // Separate filtering logic
  



  const getTests = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}getsatdata/${id}`
    );
    if (response.status === 200) {
      setData(response.data);
      navigate(`/excelimport/${id}`);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);

  const openModal = (question) => {
    setSelectedQuestion(question);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedQuestion(null);
  };
 
  const handleUpdate = async (e) => {
    e.preventDefault();
  
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}updatecsv`, selectedQuestion);
      closeModal();
      window.location.href = `/excelimport/${id}`; // Force full page reload
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };
  
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: false, // No headers in this CSV format
      complete: handleParsedData,
    });
  }; 
  const handleParsedData = (parsedData) => {
    setCsvData(parsedData.data);
  };

  const saveToMongoDB = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}savecsv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        testId: id,
        csvData: csvData,
      }),
    })
    .then(response => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Failed to save data');
      }
    })
    .then(message => {
      console.log(message); // Display success message from the server
      // You can also show the success message to the user in your React component
      // For example: set a state variable to display a message in the UI
      alert('Data saved successfully');
    })
    .catch(error => {
      console.error(error);
      // Handle error and display an error message to the user if needed
      alert('An error occurred while saving data');
    });
  };  

  const [activeTab, setActiveTab] = useState("readingWriting");

  return (
    <><div class="container-fluid page-body-wrapper">
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <h4 class="card-title"><strong>Question Upload</strong></h4>
                  </div>
                  <div class="col-6 text-right">
                    <Link class="btn btn-outline-warning" to={`/sattests`}>
                      Back to SAT Exams
                    </Link>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <div>
                      <input type="file" accept=".csv" onChange={handleFileUpload} />{" "}<button class="btn btn-outline-success" onClick={saveToMongoDB}>Import Questions</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <h4 class="card-title">SAT Tests List</h4>
                        </div>
                      </div>
                      <div class="table-responsive">
                        {isLoading ? (
                          <div className="loading-screen">
                            <i className="fa fa-spinner fa-spin"></i>
                          </div>
                        ) : null}
                        <table id="satlist" class="table table-striped table-bordered zero-configuration"
                          style={isLoading ? { display: 'none', width: '100%' } : { width: '100%' }}>
                          <thead>
                            <tr>
                              <th>Q.No</th> 
                              <th>QuestionText</th>
                              <th>Equation</th>
                              <th>Choice A</th>
                              <th>Choice B</th>
                              <th>Choice C</th>
                              <th>Choice D</th>
                              <th>Correct Answer</th>
                              <th>Topic</th>
                              <th>Sub Topic</th>
                              <th>Difficulty</th>
                              <th width="100px">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0
                              ? data.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td> 
                                    <td>{item.question_text}</td>
                                    <td>{item.equation}</td>
                                    <td>{item.choice_a}</td>
                                    <td align="center">{item.choice_b}</td>
                                    <td align="center">{item.choice_c}</td>
                                    <td>{item.choice_d}</td>
                                    <td>{item.correct_answer}</td>
                                    <td>{item.topic_name}</td>
                                    <td>{item.subtopic_name}</td>
                                    <td>{item.difficulty}</td>
                                    <td>
                                      <button
                                        className="btn btn-outline-warning"
                                        onClick={() => openModal(item)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                              : "<tr><td colspan='11'>No Data Available</td></tr>"}
                          </tbody>
                        </table>
                        {/* Edit Modal */}
                        {isModalOpen && selectedQuestion && (
                          <div className="modal" style={{ display: "block" }}>
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5>Edit Question</h5>
                                  <button className="close" onClick={closeModal}>
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <form onSubmit={handleUpdate}>
                                    <input type="hidden" name="_id" value={selectedQuestion?._id}/>
                                    {selectedQuestion?.question_type === "Math" ? (
                                    <>
                                      <div className="row mb-2">
                                          <label className="col-md-4 col-form-label text-md-right">isGridin</label>
                                          <div className="col-md-8">
                                            <select
                                              className="form-control"
                                              value={selectedQuestion?.isgridIn || ""}
                                              onChange={(e) => setSelectedQuestion({ ...selectedQuestion, isgridIn: e.target.value })}
                                            >
                                              <option value="">Select isGridin</option>
                                              <option value="FALSE">FALSE</option>
                                              <option value="TRUE">TRUE</option> 
                                            </select>
                                          </div>
                                      </div>
                                      <div className="row mb-2">
                                          <label className="col-md-4 col-form-label text-md-right">Equation</label>
                                          <div className="col-md-8">
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={selectedQuestion?.equation || ""}
                                              onChange={(e) => setSelectedQuestion({ ...selectedQuestion, equation: e.target.value })}
                                            />
                                          </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Question Context</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.context || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, context: e.target.value })}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Question Passage</label>
                                      <div className="col-md-8">
                                            <textarea
                                            className="form-control"
                                            style={{ height: "200px" }} 
                                            value={selectedQuestion?.passage || ""}
                                            onChange={(e) => setSelectedQuestion({ ...selectedQuestion, passage: e.target.value })}
                                          ></textarea>
                                      </div>
                                    </div>
                                    </>
                                  )} 
                                  <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Figure</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.figure || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, figure: e.target.value })}
                                        />
                                      </div>
                                  </div>
                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Question Text</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.question_text || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, question_text: e.target.value })}
                                        />
                                      </div>
                                    </div> 
                                    {selectedQuestion?.isgridIn === "TRUE" ? (
                                    <>
                                    
                                    <div className="row mb-2">
                                    <label className="col-md-4 col-form-label text-md-right">Correct Answer</label>
                                    <div className="col-md-8"> 
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.correct_answer || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, correct_answer: e.target.value })}
                                        />
                                    </div>
                                  </div>  
                                    </>
                                  ) : (
                                    <>
                                     <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Choice A</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.choice_a || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, choice_a: e.target.value })}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Choice B</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.choice_b || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, choice_b: e.target.value })}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Choice C</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.choice_c || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, choice_c: e.target.value })}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Choice D</label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={selectedQuestion?.choice_d || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, choice_d: e.target.value })}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                    <label className="col-md-4 col-form-label text-md-right">Correct Answer</label>
                                    <div className="col-md-8"> 
                                        <select
                                          className="form-control"
                                          value={selectedQuestion?.correct_answer || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, correct_answer: e.target.value })}
                                        >
                                          <option value="">Select Correct Answer</option>
                                          <option value="A">A</option>
                                          <option value="B">B</option>
                                          <option value="C">C</option>
                                          <option value="D">D</option>
                                        </select>
                                    </div>
                                  </div>  
                                    </>
                                  )} 
                                    
                                  {/* Topic Dropdown */}
                                    <div className="row mb-2">
                                      {/* Topic Dropdown */}
                                      <label className="col-md-4 col-form-label text-md-right">Topic</label>
                                      <div className="col-md-8">

                                      <select
                                          className="form-control"
                                          value={selectedQuestion?.topic || ""}
                                          onChange={(e) =>
                                            setSelectedQuestion({ ...selectedQuestion, topic: e.target.value })
                                          }
                                        >
                                          <option value="">Select Topic</option>
                                          {Array.isArray(topics) &&
                                            topics.map((topic) => (
                                              <option key={topic._id} value={String(topic._id )}>
                                                {topic.topic_title}
                                              </option>
                                            ))}
                                        </select>

                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                      {/* Subtopic Dropdown */}
                                      <label className="col-md-4 col-form-label text-md-right">Sub Topic</label>
                                      <div className="col-md-8">
                                      <select
                                          className="form-control"
                                          value={selectedQuestion?.subtopic || ""}
                                          onChange={(e) =>
                                            setSelectedQuestion({ ...selectedQuestion, subtopic: e.target.value })
                                          }
                                        >
                                          <option value="">Select Sub Topic</option>
                                          {Array.isArray(subtopics) &&
                                            subtopics.map((topic) => (
                                              <option key={topic._id} value={String(topic._id )}>
                                                {topic.topic_title}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>

                                    {/* Difficulty Dropdown */}
                                    <div className="row mb-2">
                                      <label className="col-md-4 col-form-label text-md-right">Difficulty</label>
                                      <div className="col-md-8">
                                        <select
                                          className="form-control"
                                          value={selectedQuestion?.difficulty || ""}
                                          onChange={(e) => setSelectedQuestion({ ...selectedQuestion, difficulty: e.target.value })}
                                        >
                                          <option value="">Select Difficulty</option>
                                          <option value="easy">Easy</option>
                                          <option value="medium">Medium</option>
                                          <option value="hard">Hard</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="modal-footer">
                                      <button type="submit" className="btn btn-success">Update</button>
                                      <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );   
}
export default ExcelImport;
