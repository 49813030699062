import { useState, useEffect  } from "react";
import axios from "axios";

const AnalyticsDashboard = () => {

  useEffect(() => {
      gettests();
  }, []);

  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" }); 

 const gettests = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}analytics`
    );
    if (response.status === 200) {
        setData(response.data);
    }
 };
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4">Analytics Dashboard</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100 text-left"> 
                <th className="p-3 border border-gray-300">Test Number</th>
                <th className="p-3 border border-gray-300">Number of Completions</th>
                <th className="p-3 border border-gray-300">Average Math Score</th>
                <th className="p-3 border border-gray-300">Average Reading & Writing Score</th>
                <th className="p-3 border border-gray-300">Average Total Score</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                <td className="p-3 border border-gray-300">{row.test_code}</td>
                <td className="p-3 border border-gray-300">{row.completions}</td>
                <td className="p-3 border border-gray-300">{Math.round(row.averageRWScore)}</td>
                <td className="p-3 border border-gray-300">{Math.round(row.averageMathScore)}</td>
                <td className="p-3 border border-gray-300">{Math.round(row.averageTotalScore)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
